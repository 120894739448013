import * as dayjs from 'dayjs'
import { EnvConfig } from '../config/EnvConfig'

const removeRedundandUrl = (banners, lang) => {
  banners.forEach((banner) => {
    if (
      banner.ctaLoggedOut.url !== undefined &&
      banner.ctaLoggedOut.url.indexOf(EnvConfig.CMS_URL.replace('{0}', lang)) >=
        0
    ) {
      banner.ctaLoggedOut.url = banner.ctaLoggedOut.url.replace(
        EnvConfig.CMS_URL.replace('{0}', lang),
        ''
      )
    }

    if (
      banner.ctaLoggedIn.url !== undefined &&
      banner.ctaLoggedIn.url.indexOf(EnvConfig.CMS_URL.replace('{0}', lang)) >=
        0
    ) {
      banner.ctaLoggedIn.url = banner.ctaLoggedIn.url.replace(
        EnvConfig.CMS_URL.replace('{0}', lang),
        ''
      )
    }
  })

  return banners
}

const getActiveBanners = (banners) => {
  const now = dayjs()
  return banners.filter(
    (b) =>
      (b.activeFrom === 0 && b.activeTo === 0) ||
      (dayjs(b.activeFrom) >= now && dayjs(b.activeTo) <= now)
  )
}

export const getBannerByType = (banners, lang, type) => {
  const activeBanners = getActiveBanners(banners)
  const formattedBanners = removeRedundandUrl(activeBanners, lang)
  return formattedBanners.filter((b) => b.vertical === type)
}
