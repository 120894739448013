import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Href = styled(Link).attrs((props) => ({
  width: props.width || 'auto',
  fontSize: props.fontSize || '1em',
  lineheight: props.lineheight || '1.8',
  bgcolor: props.bgcolor || '#000',
  padding: props.padding || '.3rem .75rem .45rem',
}))`
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineheight};
  padding: ${(props) => props.padding};
  display: inline-block;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  background-color: ${(props) => props.bgcolor};
  font-weight: 400;
`

const Cta = (props) => (
  <Href to={`${props.url}`} title={`${props.title}`} {...props}>
    {props.title}
  </Href>
)

export default Cta
