import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Container from './container'
import Cta from './cta'
import media from '../css/media'

import { AuthContext } from '../../context/authProvider'
import icon_carousel_left from '../../images/icon_carousel_left.svg'
import icon_carousel_right from '../../images/icon_carousel_right.svg'
import useDeviceDetect from '../../hooks/useDeviceDetect'

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  height: 240px;
  overflow: hidden;
  background-color: #000;

  ${media.desktop`
    height: 320px;
  `};
`

const SlidesWrapper = styled.div`
  overflow: hidden;
  position: relative;
  left: 0;
  transition-duration: 500ms;
  width: 100%;
  overflow: hidden;
  height: 100%;
`

const Slide = styled.div`
  float: left;
  position: relative;
  color: #fff;
  height: 100%;

  .container {
    height: 100%;
    padding: 0;
  }

  ${media.desktop`
    .container {
      padding: 0 1rem;
    }
  `};
`

const InnerSlide = styled.div`
  height: 100%;
  position: relative;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;

  ${media.desktop`
    text-align: left;
    background-size: contain;
    background-position: right center;
  `};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0;
    font-weight: 900;
    text-transform: uppercase;

    ${media.desktop`
      font-size: 3.5em;
      line-height: 1em;
    `};
  }

  > a {
    position: absolute;
    bottom: 2rem;
    transform: translateX(-50%);
    left: 50%;

    ${media.desktop`
      transform: initial;
      left: initial;
      font-size: 1em;
      padding: .5rem 1rem;
    `};
  }

  p {
    visibility: hidden;

    ${media.tablet`
        visibility: visible;
    `}
  }
`

const Pagination = styled.div`
  position: absolute;
  bottom: 2%;
  background-color: #000;
  border-radius: 8.5px;
  padding: 0 0.3rem;
  height: 16px;
  box-sizing: border-box;
  transform: translateX(-50%);
  left: 50%;
`

const Bullet = styled.div`
  display: inline-block;
  background-color: #5a5a5a;
  opacity: 1;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  top: -4px;

  &.active {
    background-color: #fad749;
  }

  &:last-child {
    margin: 0;
  }
`

const PrevButton = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 38px;
  height: 50px;
  cursor: pointer;
  left: 0;
  background: url(${icon_carousel_left}) 0 no-repeat;

  &.on {
    ${media.tablet`
      display: block;
    `}
  }
`

const NextButton = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 38px;
  height: 50px;
  cursor: pointer;
  right: 0;
  background: url(${icon_carousel_right}) 100% no-repeat;

  &.on {
    ${media.tablet`
      display: block;
    `}
  }
`

const Carousel = ({ slides }) => {
  const { isLoggedIn } = useContext(AuthContext)
  const { isMobile } = useDeviceDetect()
  const [curr, setCurr] = useState(0)
  const { length } = slides

  const goToNext = () => {
    setCurr(curr === length - 1 ? 0 : curr + 1)
  }

  useEffect(() => {
    setTimeout(goToNext, 5000)
    return function () {
      clearTimeout(goToNext)
    }
  })

  if (!Array.isArray(slides) || length <= 0) {
    return null
  }

  return (
    <Wrapper>
      <SlidesWrapper
        style={{
          width: `${length * 100}%`,
          transform: `translate3d(${-(100 / length) * curr}%, 0, 0)`,
        }}
      >
        {slides.map((s, i) => (
          <Slide
            style={{
              width: `${100 / length}%`,
              backgroundColor: s.backgroundColor,
            }}
            key={i}
          >
            <Container>
              <InnerSlide
                style={{
                  backgroundImage: `url('${
                    isMobile && s.imageBackgroundMobile
                      ? s.imageBackgroundMobile?.optimized?.src ||
                        s.imageBackgroundMobile.url
                      : s.imageBackground?.optimized?.src ||
                        s.imageBackground.url
                  }')`,
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: s.content }} />
                {isLoggedIn && s?.ctaLoggedIn && (
                  <Cta {...s.ctaLoggedIn} bgcolor='#31c27c' />
                )}
                {!isLoggedIn && s?.ctaLoggedOut && (
                  <Cta {...s.ctaLoggedOut} bgcolor='#31c27c' />
                )}
              </InnerSlide>
            </Container>
          </Slide>
        ))}
      </SlidesWrapper>
      {slides.length > 1 && (
        <Pagination>
          {slides.map((s, i) => (
            <Bullet
              className={`${curr === i ? 'active' : ''}`}
              key={i}
              onClick={() => setCurr(i)}
            />
          ))}
        </Pagination>
      )}
      <PrevButton
        className={`${curr > 0 ? 'on' : ''}`}
        onClick={() => setCurr(curr - 1)}
      />
      <NextButton
        className={`${curr < length - 1 ? 'on' : ''}`}
        onClick={() => setCurr(curr + 1)}
      />
    </Wrapper>
  )
}

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      ctaLoggedOut: PropTypes.object.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      imageBackground: PropTypes.object.isRequired,
    })
  ),
}

export default Carousel
