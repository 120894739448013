import React, { useEffect, useContext, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../../components/common/layout'
import Seo from '../../components/common/seo'
import Carousel from '../../components/common/carousel'
import media from '../../components/css/media'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import { getBannerByType } from '../../dataManipulation/banners'
import { AuthContext } from '../../context/authProvider'
import { getSportsbookToken } from '../../adapters/user'
import { EnvConfig } from '../../config/EnvConfig'
import storageService from '../../services/storageService'

import preloader from '../../images/preloader.gif'
import { parseQueryString } from '../../utils/generic'

const SportsContainer = styled.section`
  padding: 0;
  background-color: #1e1e1e;

  ${media.desktop`
    padding: 1rem;
  `};
`

const Loader = styled.div`
  height: 100vh;
  width: 100%;
  background: url('${preloader}') no-repeat center 30%;
  background-size: 50px;
`

const SportsPage = ({
  pageContext: { title, description, page, bannerType },
}) => {
  const location = useLocation()
  const { isMobile } = useDeviceDetect()
  const { isLoggedIn, getUserWallet } = useContext(AuthContext)

  const [token, setToken] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [sdLoaded, setSbLoaded] = useState(false)
  const user = storageService.getUser()

  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      bannersApi {
        values {
          lang
          banners {
            activeFrom
            activeFromStr
            activeTo
            activeToStr
            backgroundColor
            content
            vertical
            ctaLoggedIn {
              target
              title
              url
            }
            ctaLoggedOut {
              target
              title
              url
            }
            imageBackground {
              url
              optimized {
                src
              }
            }
            imageBackgroundMobile {
              url
              optimized {
                src
              }
            }
          }
        }
      }
    }
  `)

  const {
    bannersApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].banners

  const banners = useMemo(
    () =>
      dataExistForPage
        ? values.filter((row) => row.lang === intl.locale)[0].banners
        : [],
    [intl.locale, values, dataExistForPage]
  )

  useEffect(() => {
    if (typeof window === 'undefined') return

    const lang = window.localStorage.getItem('gatsby-intl-language') || 'en'

    let skin = 'kto.com'
    switch (lang) {
      case 'pe':
        skin = 'kto.pe'
        break
      case 'cl':
        skin = 'kto.ch'
        break
      default:
        break
    }

    const script = document.createElement('script')
    script.src = `${EnvConfig.GATSBY_SPORTS_URL}${skin}`
    script.addEventListener('load', () => setLoaded(true))
    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    const sportsLanguages = {
      en: 'en-GB',
      fi: 'fi',
      no: 'no',
      ca: 'en-ca',
      pe: 'es-ES',
      ie: 'en',
      nz: 'en',
      fr: 'fr',
      cl: 'es-ES',
      mx: 'es',
      pl: 'en',
      pt: 'pt-BR',
    }

    let ASb = null

    const placeBetCallback = () => {
      getUserWallet()
    }

    const stateChangeCallback = (params) => {
      if (window !== undefined) {
        const queryString = Object.keys(params)
          .filter((key) => key !== 'page')
          .map((key) => key + '=' + params[key])
          .join('&')

        if (queryString.length > 0) {
          window.history.pushState(null, null, `?${queryString}`)
        }
      }
    }

    const loadCallback = () => {
      setSbLoaded(true)
    }

    const initializeSportsbook = (token, walletCode) => {
      if (typeof window !== 'undefined') {
        if (window.AltenarSportsbook) {
          const params = parseQueryString(location.search)

          let initParams = {
            isfixed: false,
            fixedtop: isMobile ? 50 : 60,
            fixedbottom: 0,
            timezoneOffset: new Date().getTimezoneOffset(),
            oddstype: 'decimal',
            page: page,
            skinId: 'kto.com',
            isHashMode: false,
            lang: sportsLanguages[intl.locale],
            placebetcallback: placeBetCallback,
            statechangecallback: stateChangeCallback,
            loadcallback: loadCallback,
          }

          if (location.search !== '') {
            initParams = Object.assign(initParams, params)
          }

          const sideBanner = getBannerByType(
            banners,
            intl.locale,
            'sportsrightsection'
          )

          if (token !== undefined) {
            initParams = Object.assign(initParams, {
              token: token,
              walletcode: walletCode,
            })
          }

          if (sideBanner.length > 0) {
            let sportsRightBanners = []

            sideBanner.forEach((banner) => {
              sportsRightBanners.push({
                url: banner.imageBackground.url,
                link: isLoggedIn
                  ? `${EnvConfig.SITE_URL}/${intl.locale}${banner.ctaLoggedIn.url}`
                  : `${EnvConfig.SITE_URL}/${intl.locale}${banner.ctaLoggedOut.url}`,
                devices: ['desktop'],
              })
            })

            initParams = Object.assign(initParams, {
              banners: {
                bottomRight: sportsRightBanners,
              },
            })
          }

          ASb = new window.AltenarSportsbook('#sports-container', initParams)
        }
      }
    }

    const fetchToken = async () => {
      const response = await getSportsbookToken()
      if (response.ok) {
        if (ASb) {
          ASb.setParam('token', response.data, true)
          ASb.setParam('walletcode', EnvConfig.SPORTS_WALLET_CODE, true)
        } else {
          initializeSportsbook(response.data, EnvConfig.SPORTS_WALLET_CODE)
        }

        setToken(response.data)
      }
    }

    if (loaded && isLoggedIn && !token) fetchToken()
    if (loaded && !isLoggedIn && !user) initializeSportsbook()
  }, [
    loaded,
    isLoggedIn,
    token,
    user,
    banners,
    intl.locale,
    isMobile,
    page,
    getUserWallet,
    location.search,
  ])

  useEffect(() => {
    const walletCallback = () => {
      getUserWallet()
    }

    let walletInterval

    if (isLoggedIn) {
      walletInterval = setInterval(walletCallback, 10000)
    }

    return function () {
      clearInterval(walletInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    storageService.setValue('lobby', location.pathname)
  })

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: `${title}` })}
        description={intl.formatMessage({ id: `${description}` })}
      />
      {!isLoggedIn && (
        <Carousel
          slides={getBannerByType(
            banners,
            intl.locale,
            bannerType ? bannerType : 'sports'
          )}
        />
      )}
      <SportsContainer>
        {!sdLoaded && <Loader />}
        <div id='sports-container' />
      </SportsContainer>
    </Layout>
  )
}

export default SportsPage
