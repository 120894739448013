import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const MainWrapper = styled.div`
  max-width: 100%;
  padding: 0 1rem;

  @media (min-width: 1250px) {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0;
  }
`

const Container = ({ children }) => {
  return <MainWrapper className='container'>{children}</MainWrapper>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
